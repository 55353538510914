import { UserHeaderParams, UserQueryParams } from '../types/users';
import { api } from './config';
import { CreateUserProps } from './types/users';

export const getAllUsers = async (
  params?: UserQueryParams,
  headerParams?: UserHeaderParams
) => {
  const headers: Record<string, string> = {};

  if (headerParams?.companyId) {
    headers['company_id'] = headerParams.companyId;
  }

  const { status, data } = await api.get(`/v1/partners/users`, {
    params: params,
    headers: headers,
  });

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const getUser = async (id: string) => {
  const { status, data } = await api.get(`/v1/partners/users/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};

export const createUser = async (
  payload: CreateUserProps,
  headerParams?: UserHeaderParams
) => {
  const headers: Record<string, string> = {};

  if (headerParams?.companyId) {
    headers['company_id'] = headerParams.companyId;
  }

  const { status, data } = await api.post('/v1/partners/users', payload, {
    headers: headers,
  });

  if (status === 200 && data) {
    return { data, status };
  }

  return false;
};

export const editUser = async (
  id: string,
  payload: CreateUserProps,
  headerParams?: UserHeaderParams
) => {
  const headers: Record<string, string> = {};

  if (headerParams?.companyId) {
    headers['company_id'] = headerParams.companyId;
  }
  const { status, data } = await api.put(`/v1/partners/users/${id}`, payload, {
    headers: headers,
  });

  if ([200, 201].includes(status) && data) {
    return data;
  }

  return false;
};

export const deleteUser = async (id: string) => {
  const { status, data } = await api.delete(`/v1/partners/users/${id}`);

  if (status === 200 && data) {
    return data;
  }

  return false;
};
