import { api } from './config';

export const listReports = async ({
  id,
  page,
  limit,
  query,
}: {
  page?: number;
  limit?: number;
  query?: string;
  id?: string;
}) => {
  const searchFilter = query ? `?year=${query}` : '';

  const { data } = await api.get(
    `/v2/partners/companies/reports${searchFilter}`
  );

  return data;
};
