import { isValidCPF } from '@brazilian-utils/brazilian-utils';
import { Button, Icon, Input, Select, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as z from 'zod';
import useForm from '../../hooks/useForm';

import { Controller } from 'react-hook-form';
import { LayoutBase } from '../../components/LayoutBase';
import { removeAllNumberAndSpecialCharacters } from '../../utils/texts';
import { isValidPhoneNumber } from '../../utils/validate-phone-number';
import { AxiosError } from 'axios';
import {
  createUser,
  deleteUser,
  editUser,
  getUser,
} from '../../services/users';
import { IonRefresher, IonRefresherContent, useIonAlert } from '@ionic/react';
import { UserParamsProps } from '../User/types';
import { UserData } from '../../types/users';
import { Loading } from '../../components/Loading';

const schema = z.object({
  full_name: z.string().nonempty({ message: 'campo obrigatório' }),
  email: z
    .string()
    .nonempty({ message: 'campo obrigatório' })
    .email({ message: 'email inválido' }),
  cellphone: z
    .string()
    .nonempty({ message: 'campo obrigatório' })
    .refine((value) => isValidPhoneNumber(value), {
      message: 'número inválido',
    }),
  document_number: z
    .string()
    .nonempty({ message: 'campo obrigatório' })
    .refine((value) => isValidCPF(value), {
      message: 'cpf inválido',
    }),
  user_types: z.string().nonempty({ message: 'campo obrigatório' }),
});

const UserBelongingCompanyForm = () => {
  const { state } = useLocation<{
    companyId: string;
  }>();
  const { id }: UserParamsProps = useParams();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [present] = useIonAlert();
  const [currentUser, setCurrentUser] = useState<UserData>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    schema,
    mode: 'onChange',
  });

  const goToAllUsersPage = useCallback(() => {
    history.push('/users');
  }, [history]);

  const handleUserRequest = useCallback(
    async (payload: any, requestType: 'create' | 'edit') => {
      setIsLoading(true);

      const formattedPayload = {
        ...payload,
        cellphone: payload?.cellphone?.replace(/[^0-9]/g, ''),
        document_number: payload?.document_number?.replace(/[_.-]/g, ''),
      };

      try {
        if (requestType === 'create') {
          await createUser(formattedPayload, { companyId: state?.companyId });
          Toast.success('usuário criado com sucesso');
        } else if (requestType === 'edit' && id) {
          await editUser(id, formattedPayload, {
            companyId: state?.companyId,
          });
          Toast.success('usuário atualizado com sucesso');
        }
        goToAllUsersPage();
      } catch (err) {
        const error = err as AxiosError;
        const message =
          error.response?.data?.message ||
          `ocorreu um erro ao ${
            requestType === 'create' ? 'criar' : 'editar'
          } usuário`;
        Toast.crema(message);
      } finally {
        setIsLoading(false);
      }
    },
    [id, goToAllUsersPage, state?.companyId]
  );

  const afterDeleteUserById = useCallback(() => {
    setIsLoading(false);
    goToAllUsersPage();
  }, [setIsLoading, goToAllUsersPage]);

  const deleteUserById = useCallback(
    async (id: string) => {
      try {
        await deleteUser(id);
        Toast.success('usuário deletado com sucesso');
      } catch (err) {
        const error = err as AxiosError;
        const message =
          error.response?.data?.message ?? 'ocorreu um erro ao deletar usuário';
        Toast.crema(message);
      } finally {
        afterDeleteUserById();
      }
    },
    [afterDeleteUserById]
  );

  const tryGetUserById = useCallback(async (id: string) => {
    setIsLoading(true);
    const response = await getUser(id);
    setCurrentUser(response.user);
  }, []);

  function afterTryGetUserById() {
    setIsLoading(false);
  }

  const getUserById = useCallback(
    async (id: string) => {
      try {
        await tryGetUserById(id);
      } catch (err) {
        const error = err as AxiosError;

        if (error.response?.data?.message) {
          const { message } = error.response.data;
          Toast.crema(message);
        } else {
          Toast.crema('ocorreu um erro ao carregar detalhes do usuário');
        }
      } finally {
        afterTryGetUserById();
      }
    },
    [tryGetUserById]
  );

  const onSave = useCallback(
    async (payload: any) => {
      await handleUserRequest(payload, id ? 'edit' : 'create');
    },
    [handleUserRequest, id]
  );

  useEffect(() => {
    setValue('full_name', currentUser?.full_name);
    setValue('email', currentUser?.email);
    setValue('cellphone', currentUser?.cellphone);
    setValue('document_number', currentUser?.document_number);
    setValue('user_types', currentUser?.user_types);
  }, [currentUser, setValue]);

  useEffect(() => {
    if (id !== undefined) getUserById(id);
  }, [getUserById, id]);

  return (
    <LayoutBase title="Usuários">
      <IonRefresher
        slot="fixed"
        onIonRefresh={(e) => setIsLoading(true)}
        disabled={isLoading}
      >
        <IonRefresherContent />
      </IonRefresher>
      {isLoading ? (
        <div className="w-full h-full bg-red">
          <Loading />
        </div>
      ) : (
        <>
          <form>
            <section className="flex flex-col mt-5 px-5 space-y-4">
              <Controller
                control={control}
                name="full_name"
                render={({ field: { onChange, ...props } }) => (
                  <Input
                    required
                    placeholder="nome completo"
                    error={errors.full_name?.message}
                    onChange={(e) =>
                      onChange(
                        removeAllNumberAndSpecialCharacters(e.target.value)
                      )
                    }
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field: { ...props } }) => (
                  <Input
                    required
                    type="email"
                    placeholder="E-mail"
                    error={errors.email?.message}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                name="cellphone"
                render={({ field: { value, ...props } }) => (
                  <Input
                    required
                    type="tel"
                    placeholder="Telefone"
                    mask="CELLPHONE"
                    error={errors.cellphone?.message}
                    value={value ?? ''}
                    {...props}
                  />
                )}
              />
              <Controller
                control={control}
                name="document_number"
                render={({ field: { ...props } }) => (
                  <Input
                    required
                    type="tel"
                    placeholder="CPF"
                    mask="CPF"
                    error={errors.document_number?.message}
                    {...props}
                  />
                )}
              />
              <Controller
                name="user_types"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    required
                    label="Tipo de usuário"
                    options={[{ label: 'Vendedor', value: 'pdv' }]}
                    error={error?.message}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </section>
            <section className="grid sm:grid-cols-2 gap-4 px-5 mt-8 mb-5">
              <Button
                className="sm:w-1/2"
                full
                variant="solid"
                color="neon"
                onClick={handleSubmit(onSave)}
              >
                {id ? 'Atualizar' : 'Cadastrar'}
              </Button>
              <Button
                className="sm:w-1/2"
                onClick={goToAllUsersPage}
                variant="outline"
                color="neon"
                full
              >
                Cancelar
              </Button>
            </section>
          </form>
          {id && (
            <section className="mt-8 px-5">
              <section className="border-t border-crema-500 py-4 w-full flex items-center justify-center">
                <Button
                  color="neon"
                  variant="light"
                  full
                  onClick={() => {
                    if (currentUser?.id) {
                      present({
                        header: 'Deletar usuário',
                        message: 'Deseja realmente deletar este usuário?',
                        buttons: [
                          'Não',
                          {
                            text: 'Sim',
                            handler: () => deleteUserById(currentUser.id),
                          },
                        ],
                      });
                    }
                  }}
                  className="flex justify-center items-center"
                >
                  <span className=" flex justify-center items-center">
                    <Icon.MdOutlineDelete className="w-6 h-6 mr-2" />
                    {isLoading ? 'Aguarde...' : 'Excluir'}
                  </span>
                </Button>
              </section>
            </section>
          )}
        </>
      )}
    </LayoutBase>
  );
};

export default UserBelongingCompanyForm;
