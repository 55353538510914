import { IconType } from 'react-icons';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../assets/logo.jpg';
import useAuth from '../../hooks/useAuth';
import { Icon, Toast } from 'oialbert-ui';
import {
  checkLimit,
  checkReportAccessPermission,
} from '../../services/contracts';
import { useCallback, useEffect, useState } from 'react';
import { PartnersContractsItemsPermission } from '../../types/partner-contracts-items-permission';
import { LIMIT_TYPE } from '../../pages/Promotions/types';

interface NavLinkProps {
  to: string;
  icon: IconType;
  text: string;
}

const NavbarLink: React.FC<NavLinkProps> = ({ to, icon: Icon, text }) => (
  <Link
    to={to}
    className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black"
  >
    <Icon className="w-5 h-5 text-neon-900" />
    <span className="text-sm">{text}</span>
  </Link>
);

const ConditionalLink: React.FC<NavLinkProps & { condition: boolean }> = ({
  condition,
  ...props
}) => {
  if (!condition) return null;
  return <NavbarLink {...props} />;
};

export const Navbar: React.FC = () => {
  const history = useHistory();
  const { user, company, setCompany, signOut } = useAuth();
  const [showChatLink, setShowChatLink] = useState<boolean>(false);

  const isUserPDVOrDeliveryPermission =
    user?.partner?.user_types === 'pdv' ||
    user?.partner?.user_types === 'delivery';

  const [reportPermission, setReportPermission] =
    useState<PartnersContractsItemsPermission>();

  const shouldShowReportsLink =
    reportPermission?.success && !isUserPDVOrDeliveryPermission;

  const renderCheckInLink = () => (
    <ConditionalLink
      to="/check-in"
      icon={Icon.MdTaskAlt}
      text="check-in"
      condition={user?.company_partner?.user_types !== 'delivery'}
    />
  );

  const renderExtraLinks = () => {
    if (['pdv', 'delivery'].includes(user?.partner.user_types as string))
      return null;
    return (
      <>
        <NavbarLink
          to="/cashbacks/expired"
          icon={Icon.MdAttachMoney}
          text="cashbacks expirados"
        />
        <NavbarLink
          to="/promotions"
          icon={Icon.MdOutlineShoppingBag}
          text="promoções"
        />
        <NavbarLink to="/users" icon={Icon.MdOutlineGroup} text="usuários" />
        <NavbarLink
          to="/extra-services"
          icon={Icon.MdAddBusiness}
          text="serviços extras"
        />
      </>
    );
  };

  const handleCompanyChange = () => {
    setCompany(null);
    history.push('/select-company');
  };

  const handleCheckLimit = useCallback(async () => {
    try {
      const response = await checkReportAccessPermission();
      setReportPermission(response);
    } catch (error) {
      Toast.crema('ocorreu um erro ao carregar permissões');
    }
  }, []);

  const handleCheckLimitChat = useCallback(async () => {
    try {
      const responseFree = await checkLimit(LIMIT_TYPE.ai_free);
      const responsePremium = await checkLimit(LIMIT_TYPE.ai_premium);
      setShowChatLink(responseFree?.limit > 0 || responsePremium?.limit > 0);
    } catch (error) {}
  }, []);

  useEffect(() => {
    (async () => {
      await handleCheckLimit();
      await handleCheckLimitChat();
    })();
  }, [handleCheckLimit, handleCheckLimitChat]);

  return (
    <section className="hidden sm:flex flex-col w-64 min-h-full overflow-y-auto items-start justify-start bg-white shadow-lg shadow-gray-200 rounded-xl px-3">
      <img src={Logo} alt="" className="mx-3 h-24 mt-8 mb-5" />
      <button
        className="flex card flex-row justify-between w-full items-center p-2 border rounded-md border-gray-100 cursor-pointer hover:bg-gray-50 py-4 text-gray-500 hover:text-black mb-4 shadow-md"
        onClick={handleCompanyChange}
      >
        <div className="flex flex-col">
          <span className="text-xs text-gray-500">empresa selecionada</span>
          <span className="text-sm font-bold">{company?.name}</span>
        </div>
        <Icon.MdOutlineKeyboardArrowDown className="w-6 h-6 text-black" />
      </button>
      <NavbarLink to="/" icon={Icon.MdOutlineHome} text="home" />
      {renderCheckInLink()}
      <NavbarLink
        to="/financial-order"
        icon={Icon.MdAddBusiness}
        text="pedidos"
      />
      {renderExtraLinks()}
      {shouldShowReportsLink && (
        <NavbarLink to="/reports" icon={Icon.MdEditDocument} text="reports" />
      )}
      <NavbarLink
        to="/settings"
        icon={Icon.MdOutlineSettings}
        text="configurações"
      />
      {showChatLink && (
        <NavbarLink
          to="/ai/prompts"
          icon={Icon.MdQuestionAnswer}
          text="pergunte ao albert"
        />
      )}
      <button
        className="flex justify-start gap-3 items-center pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black"
        onClick={signOut}
      >
        <Icon.MdLogout className="w-5 h-5 text-neon-900" />
        <span className="text-sm">Sair</span>
      </button>
    </section>
  );
};
