/* eslint-disable react-hooks/rules-of-hooks */
import parsePhoneNumber from 'libphonenumber-js';

export const isValidPhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumber(value, 'BR');
  const isValid = phoneNumber?.isValid();
  if (isValid === true) {
    return true;
  } else {
    return false;
  }
};
